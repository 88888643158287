.search-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.search-filter-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;

  padding-bottom: 10px;
}

.search-filter-title {
  font-size: 16px;
  color: var(--default-text-color);
}

.search-filter-frame {
  border-style: solid;
  border-width: 1px;
  border-color: var(--filter-border-color);
  border-radius: 10px;

  padding: 10px;

  margin-left: 5px;
  margin-right: 5px;

  width: 80%;
}

#match-filter-wrapper .search-filter-frame {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}

#bonus-filter-wrapper .search-filter-checkbox-wrapper {
  display: flex;
  justify-items: center;
  align-items: center;
}

.search-filter-slider-percentage {
  display:flex;
  font-size: 16px;
  color: var(--default-text-color);
  margin-top: 4px;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.search-filter-checkbox-name {
  font-size: 14px;
  color: var(--default-text-color);
  padding-left: 2px;
}

.matching-text-navigator {
  width: 25px;
  background-color: none;
  border: solid 1px var(--filter-background-color);
  background: none;
  margin-right: 3px;

  text-align: center;
}

.matching-beta-announcer{
  margin-top: 10px;
  color: var(--weaker-text-color);
}

.matching-text-navigator:hover {
  border-color: var(--filter-border-color);
}

.matching-text-navigator:focus {
  border-color: var(--filter-border-color);
}

/* Slider */
#matching {
  cursor: pointer;
}

input[type="range"] {
  -webkit-appearance: none;
  background: var(--global-background-color);
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: var(--filter-background-color);
  height: 5px;
}

input[type="range"]::-moz-range-track {
  background: var(--filter-background-color);
  height: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background: var(--filter-background-color);
  margin-top: -5px;
  border-radius: 50%;
}

input[type="range"]::-webkit-slider-thumb:hover {
  border: 1px solid var(--filter-border-color);
}

input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background: var(--filter-background-color);
  margin-top: -5px;
  border-radius: 50%;
}

/* Checkbox */
.search-filter-checkbox {
  -webkit-appearance: none;
  border-radius: 3px;
  background-color: var(--filter-background-color);
  border: 1px solid var(--filter-background-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;

  cursor: pointer;
}

.search-filter-checkbox:checked:after {
  content: "\2714";
  font-size: 20px;
  position: absolute;
  top: -5px;
  left: 1px;
  bottom: 100px;
  color: var(--card-background-color);
}

.search-filter-checkbox:checked {
  background-color: var(--filter-background-color);

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.search-filter-checkbox:hover {
  border: 1px solid var(--filter-border-color);
}

/* Max width for smart phones */
@media screen and (max-width: 960px) {
  .search-filters {
    flex-direction: row;

    align-items: center;
    justify-content: center;
  }

  .search-filter-wrapper {
    flex-grow: 0;
  }

  #bonus-filter-wrapper .search-filter-bonus-checkbox-list {
    display: flex;
  }

  #bonus-filter-wrapper .search-filter-checkbox-wrapper {
    padding-right: 10px;
  }

  #match-filter-wrapper {
    align-self: flex-start;
  }

  #bonus-filter-wrapper {
    align-self: flex-start;
  }

  #bonus-filter-wrapper .search-filter-frame {
    min-width: 250px;
  }

  .search-filter-frame {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    width: auto;
    height: 65px;

    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 920px) {
  .search-filters{
    flex-wrap: wrap;
  }
}