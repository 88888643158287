.about-wrapper {
  padding-left: var(--global-window-border-padding);
  padding-right: var(--global-window-border-padding);

  min-height: 650px;
}

.about-wrapper h1{
  margin-top: 0;
}

.about-wrapper #about-steam-image {
  padding-left: 2.5px;
  padding-right: 2.5px;
  width: 50px;
  height: 50px;
}

.about-wrapper #about-steam-image:hover{
  width: 55px;
  height: 55px;
}

.about-wrapper #about-gog-image {
  padding-left: 2.5px;
  padding-right: 2.5px;
  width: 50px;
  height: 50px;
}

.about-wrapper #about-gog-image:hover{
  width: 55px;
  height: 55px;
}

@media screen and (max-width: 960px) {
  .about-wrapper {
    padding-left: var(--global-window-border-padding-mobile);
    padding-right: var(--global-window-border-padding-mobile);

    min-height: 500px;
  }
}
