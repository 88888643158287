.connection-error-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.connection-error-wrapper h1{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: normal;
}

.connection-error-text-wrapper {
  color: var(--default-text-color);
  padding-bottom: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;
}

.connection-error-header {
  font-size: 40px;
  padding-bottom: 5px;
}

.connection-error-report {
  color: var(--weaker-text-color);
  font-size: 18px;
  text-align: center;
}

.connection-error-image {
  border-radius: 8px;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.158);
}

@media screen and (max-width: 1400px) {
  .connection-error-image-wrapper{
    margin-bottom: 60%;
  }

  .connection-error-image{
    width: 600px;
  }
}

@media screen and (max-width: 980px) {
  .connection-error-image-wrapper{
    margin-bottom: 60%;
  }

  .connection-error-image{
    width: 500px;
  }
}

@media screen and (max-width: 800px) {
  .connection-error-image-wrapper{
    margin-bottom: 60%;
  }

  .connection-error-image{
    width: 300px;
  }
}