.browsing-wrapper {
  padding-left: var(--global-window-border-padding);
  padding-right: var(--global-window-border-padding);

  min-height: 500px;

  margin-bottom: 140px;
}

.browsing-navigation-filter-wrapper {
  padding-top: 18px;

  display: flex;
}

.browsing-navigator-wrapper {
  width: 80%;
}

.browsing-filters-wrapper-right {
  flex-grow: 1;
}

.browsing-filters-wrapper-left {
  display: none;
}

@media screen and (max-width: 960px){
  .browsing-wrapper {
    margin-bottom:55%;
  }
}

/* Max width for smart phones */
@media screen and (max-width: 960px) {
  .browsing-wrapper {
    padding-left: var(--global-window-border-padding-mobile);
    padding-right: var(--global-window-border-padding-mobile);
  }

  .browsing-navigation-filter-wrapper {
    flex-direction: column;
  }

  .browsing-filters-wrapper-right {
    display: none;
  }

  .browsing-filters-wrapper-left {
    display: inline;
  }

  .browsing-navigator-wrapper {
    width: 100%;
  }
}
