:root {
  /*Variable Definition*/
  /*Main*/
  --global-background-color: rgba(222, 222, 222, 1);
  --hexagon-color: rgba(155, 50, 238, 0.95);
  --navbar-background-color: rgba(210, 210, 210, 0.4);
  --catchphrase-text-color: rgba(0, 0, 0, 1);
  --browse-header-text-color: rgba(0, 0, 0, 1);
  --footer-background-color: rgba(210, 210, 210, 0.603);
  /*Nav Menu*/
  --nav-mobile-background-color: rgba(190, 134, 233, 1);
  --nav-mobile-hover-color: rgba(155, 50, 238, 1);
  --nav-mobile-text-color: rgb(236, 236, 236);
  --nav-mobile-border-color: rgba(155, 50, 238, 1);
  /*Main Text*/
  --default-text-color: rgba(50, 50, 50, 1);
  --weaker-text-color: rgba(86, 86, 86, 1);
  --special-text-color: rgba(163, 94, 219, 1);
  /*Main Text*/
  --searchbar-background-color: rgb(247, 247, 247);
  --searchbar-text-color: rgba(80, 80, 80, 1);
  --searchbar-placeholder-color: rgb(87, 87, 87);
  --searchbar-border-color: rgb(139, 139, 139);
  --searchbar-hover-border-color: rgb(10, 10, 10);
  --search-icon-color: rgba(53, 53, 53, 1);
  --searchbar-suggestion-background: rgba(210, 210, 210);
  --searchbar-suggestion-divisionline-color: rgba(50, 50, 50, 1);
  --searchbar-suggestion-hovercolor: var(--global-background-color);
  /*Card*/
  --card-background-color: rgba(80, 46, 107, 1);
  --card-background-hover-color: rgb(106, 63, 141);
  --card-title-color: rgba(255, 255, 255, 1);
  --card-title-color-weak: rgb(228, 228, 228);
  --card-tag-text-color-a: rgba(174, 174, 174, 1);
  --card-tag-text-color-b: rgba(255, 255, 255, 0.85);
  --cardbutton-background-color: rgb(155, 76, 219);
  --cardbutton-background-color-hover: rgb(167, 99, 223);
  --cardbutton-text-color: rgba(255, 255, 255, 1);
  --carddate-text-color: rgba(150, 150, 150, 1);
  --morelikethis-card-color: rgba(80, 46, 107, 0.521);
  /*Filter*/
  --filter-border-color: rgba(163, 94, 219, 1);
  --filter-background-color: rgba(182, 153, 205, 1);
  /*Detail Page*/
  --detail-tag-background-color: rgba(182, 153, 205, 1);
  --detail-border-color: rgba(96, 56, 118, 1);
  --detail-sourcebutton-background-color: rgba(80, 46, 107, 1);
  --detail-sourcebutton-background-hover-color: rgb(106, 63, 141);
  --detail-sourcebutton-text-color: rgba(255, 255, 255, 1);
  /*About Page*/
  --about-block-hover-color: rgba(182, 153, 205, 0.452);

  /* Padding */
  --global-window-border-padding: 18.75%;
  --global-window-border-padding-mobile: 5%;

  background-color: var(--global-background-color);
}

html{
  background: url("./images/main_background.svg");
  background-position-x: -10px;
  background-position-y: -160px;
}

html {
  overflow: -moz-scrollbars-vertical; 
  overflow-y: scroll;
}