.landing-page-search-panel {
  height: 525px;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  flex-grow: 1;
}

.landing-page-search-panel .search-panel-search-bar{
  width: 55%;
  min-width: 700px;
}

.landing-page-search-panel .search-bar-input{
  height: 40px;
}

.landing-page-search-panel .search-results{
  width: 55%;
  min-width: 700px;
  max-height: 450px;
}

.landing-page-search-panel .search-product-result{
  flex-direction: row;
}

.landing-page-search-panel .search-product-result-desc{
  align-items: flex-start;
}

.landing-page-search-panel .search-panel-header {
  padding-bottom: 15px;
}

.landing-page-search-panel .search-panel-image-wrapper{
  margin-left: 35px;
  margin-right: 35px;

  padding-bottom: 15px;
}

.landing-page-search-panel .search-panel-search-bar {
  display: block;

  padding-bottom: 10px;
  justify-self: center;
}

.landing-page-search-panel .search-panel-main-phrase {
  color: var(--catch-phrase-text-color);
  font-size: 24px;

  padding-bottom: 15px;
}

.landing-page-search-panel .search-panel-footer {
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 7px;
}

.landing-page-search-panel .search-panel-footer:hover{
  border-radius: 10px;

  background-color: var(--about-block-hover-color);
}

.landing-page-search-panel .search-panel-icon-announcer {
  font-size: 20px;
  padding-right: 20px;
}

.landing-page-search-panel .search-panel-icon-wrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 20px;
  align-items: center;
}

.landing-page-search-panel .logo-steam {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.landing-page-search-panel .logo-gog {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

@media screen and (max-width: 1450px) {
}

/* Max width for smart phones */
@media screen and (max-width: 960px) {
  .landing-page-search-panel .search-panel-search-bar{
    width: 80%;
    min-width: 450px;
  }

  .landing-page-search-panel .search-results{
    width: 80%;
    min-width: 450px;
  }

  .landing-page-search-panel .search-product-result-desc{
    align-items: center;
  }

  .landing-page-search-panel {
    height: 400px;
  }
}

@media screen and (max-width: 920px) {
  .search-panel-header{
    width: 450px; 
    height: 97.82px;
  }
}

@media screen and (max-width: 800px) {
  .landing-page-search-panel .search-product-result{
    flex-direction: column;
  }

  .landing-page-search-panel .search-product-result-desc{
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .landing-page-search-panel .search-panel-search-bar{
    min-width: 350px;
  }

  .landing-page-search-panel .search-results{
    min-width: 350px;
  }
}

@media screen and (max-width: 500px) {
  .search-panel-header{
    width: 370px; 
    height: 80.43px;
  }
}

@media screen and (max-width: 380px) {
  .search-panel-header{
    width: 340px; 
    height: 73.91px;
  }

  .landing-page-search-panel .search-panel-search-bar{
    min-width: 330px;
  }

  .landing-page-search-panel .search-results{
    min-width: 330px;
  }
}